.card.card--question {
  font-size: 0.75rem;

  .card-header {
    display: flex;
    flex-direction: row;
    align-items: center;

    .card-title {
      font-weight: bold;
      line-height: 1;
    }
    .card-subtitle {
      font-weight: normal;
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .card-body {
    padding: 0.4rem;

    &:not(:first-child) {
      padding-top: 0;
    }
  }

  .card-footer {
    background: #fafafa;
  }

  .label {
    &:not(.label-primary):hover {
      background: #fafafa;
      border-color: #ccc;
    }
  }
}

.questions-list {
  padding-top: 10px;
}

@media (max-width: 480px) {
  .questions-list {
    padding-top: 20px;
    padding-bottom: 200px;
  }

  .questions-list.order-by-active {
    padding-top: 40px;
  }

  .questions-list.theme-active {
    padding-bottom: 238px;
  }
}
