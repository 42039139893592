.container--client {
  background: #FAFAFA;
  height: 100vh;
  max-width: 800px;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .image {
    img {
      margin: 0 auto;
      display: block;
    }
  }

  .image--footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
