.container--mode-green {
    &.container {
        background: #00ff00;
        padding-left: 0 !important;
        padding-right: 0 !important;
        max-width: 100vw;
    }

    .hero {
        display: none;
    }

    .card {
        position: fixed;
        /*left: 10vw;
            right: 10vw;*/
        bottom: 3vh;
        width: 70vw;
        // background: #00537d;
        // color: white;
        font-size: 16px;
        border: 0;
    }

    // .content .card-header {
    //     text-transform: uppercase;
    // }

    .card-body {
        font-size: 1.5em;
    }
}

.card {
    &.slide {
        .question {
            &__theme {
                font-size: 0.75em;
            }

            &__user {
                &-extraFields {
                    font-size: 0.7em;
                    color: #aaa;
                }
            }
        }
    }
}
