.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.modal-label {
  color: #5855d8;
  font-weight: bold;
}

.mobile-footer-button h3 {
  font-size: 1.1rem;
}

.card {
  border: 0;
  box-shadow: 0 0.25rem 1rem rgba(48, 55, 66, .15);
}

.card-footer>.btn {
  margin-right: 4px;
}

.container--speaker {

  .card {
    font-size: 1.8em;
  }

  .btn {
    height: 2.5rem;
    font-size: 1.2rem;
    padding: .5rem .8rem;
  }

  .btn.btn-action {
    width: 2.5rem;
  }
}

.container--slide {
  max-width: 1200px;
  margin: 0 auto;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .empty {
    width: 90%;
  }

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .card {
    // font-size: 2.4em;
    font-size: 3.8vh;
    margin-bottom: 3vh;

    .card-body {
      // padding: 3vh;
      // padding: 1.2rem;
    }
  }

  .btn {
    height: 2.5rem;
    font-size: 1.2rem;
    padding: .5rem .8rem;
  }

  .btn.btn-action {
    width: 2.5rem;
  }


  .content-grid--2 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: initial;

    .card {
      margin-left: 8px;
      margin-right: 8px;
      width: calc(50% - 16px);
    }
  }
}


.btn {
  border-color: var(--ac-color-primary);
  color: var(--ac-color-primary);
}

.btn:focus,
.btn:hover {
  border-color: var(--ac-color-primary);
}

.btn.active,
.btn:active {
  background: var(--ac-color-primary);
  border-color: var(--ac-color-primary);
  color: #fff;
}

.btn.btn-primary,
.btn.btn-primary.active,
.btn.btn-primary:active,
.btn.btn-primary:focus,
.btn.btn-primary:hover {
  background: var(--ac-color-primary);
  border-color: var(--ac-color-primary);
  color: #fff;
}

.form-input:focus {
  border-color: var(--ac-color-primary);
  box-shadow: 0 0 0 0.1rem rgba(87, 85, 85, 0.2);
}

.tab .tab-item a.active,
.tab .tab-item.active a {
  border-bottom-color: var(--ac-color-primary);
  color: var(--ac-color-primary);
}

.tab .tab-item a:focus,
.tab .tab-item a:hover {
  color: var(--ac-color-primary);
}

.badge:not([data-badge])::after,
.badge[data-badge]::after {
  background: var(--ac-color-primary);
  margin-left: 2px;
}

.tag {
  background-color: var(--ac-color-primary);
  color: #fff;
}

.question-response label {
  color: var(--ac-color-primary);
}

.label.label-primary,
.avatar {
  background: var(--ac-color-primary);
}

.form-checkbox input:checked+.form-icon, .form-radio input:checked+.form-icon, .form-switch input:checked+.form-icon {
  background: var(--ac-color-primary);
  border-color: var(--ac-color-primary);
}