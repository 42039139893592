@media (max-width: 480px) {
  .tab-filter {
    position: fixed;
    margin-top: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    padding: 0.5em;
    background: #fafafafa;
  }
}

.tab-filter {
  .label {
    cursor: pointer;
  }
}
