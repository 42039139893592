.btn-primary.mobile-button {
  display: none
}

.mobile-block {
  display: none
}

.mobile-footer-button {
  background: #FAFAFA;
  width: 100%;
  margin: auto;
  padding: 7px;
}

.form-input {
resize: none;
};

@media (max-width: 480px) {
  .mobile-footer-button {
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    box-shadow: 0 -5px 5px -5px #333;
  }

  .question-block {
    &.button {
      display: none;
    }
  }
  
  .mobile-button{
    width: 100%;
  }
  
  .question-block.textarea {
    display: block;
  }

  .question-block.no-questions {
    display: block
  }

  .mobile-block {
    display: block
  }



  .btn-primary.mobile-button {
    display: block;
  }

  .mobile-button.no-questions {
    display: none;
  }

  .btn-primary.btn-action {
    float: right
  }

}