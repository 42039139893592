.card-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  button {
    border-radius: 4px !important;
  }

  .left {
    display: flex;
    flex-direction: row;
    align-items: center;

    .form-group {
      cursor: pointer;
      margin-bottom: 0;
    }

    .form-switch {
      i.form-icon {
        margin-left: 0.3em;
      }

      i.fas {
        color: #838890;
        margin-left: 0.5em;
        margin-right: 0.2em;
      }

      i.fas.active {
        color: var(--ac-color-primary);
      }
    }

    label.form-switch {
      padding-right: 2.0rem;
      padding-left: 0.4rem;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      margin-right: 1em;
      cursor: pointer;

      i.fas {
        margin-left: 0;
        margin-right: 0.5em;
      }

      i.form-icon {
        left: initial;
        right: 0.2rem;
      }
    }
  }

  .btn-group {
    button {
      margin-right: 0.5em;
    }
  }
}
