.order-tab {
  overflow: hidden;
  background-color: #f1f1f1;

  margin-top: 8px;

  button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
  }

  button:hover {
    background-color: #ddd;
  }

  button.active {
    background-color: #ccc;
  }
}

.tab-content {
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow: hidden;
  .questions-container{
    height: 100%;
    overflow-y: auto;
  }
  border-top: none;
}
